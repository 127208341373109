/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React from "react";

/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import "./style-welcome.css";
import useWelcomeCtr from "../../controller/use-welcom-ctr";
import Footer from "../widget/footer/footer";
import Header from "../../screen/widget/index";
import "react-multi-carousel/lib/styles.css";
import maa from "../../resources/images/solveB/maa.svg";
import ip from "../../resources/images/solveB/ip.svg";
import restructuring from "../../resources/images/solveB/restructuring.svg";
import bootstraping from "../../resources/images/solveB/bootstraping.svg";
import white from "../../resources/images/solveB/white.svg";
import early2 from "../../resources/images/solveB/early-f.svg";
import legal from "../../resources/images/solveB/legal-ip.svg";
import asset from "../../resources/images/solveB/ip-mon.svg";
import copyright from "../../resources/images/solveB/copyright.svg";
import other from "../../resources/images/solveB/other.svg";
import later2 from "../../resources/images/solveB/later-f.svg";
import alternative from "../../resources/images/solveB/alternative.svg";
import early from "../../resources/images/early-funding.svg";
import later from "../../resources/images/later-funding.svg";
import ma from "../../resources/images/manda.svg";
import strategic from "../../resources/images/strategic-partner.svg";
import more from "../../resources/images/more.svg";
import howitwork from "../../resources/images/how-it-work.svg";
import { Link } from "react-router-dom";
import { rootState } from "../../redux/reducers";
import { useSelector } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { LoadingAllPage } from "../widget/loader";

export default function Welcome() {
  const { understoodCoockies, setUnderstoodCoockies } = useWelcomeCtr();

  const { keycloak } = useKeycloak();

  const userState = useSelector((state: rootState) => state.userReducer);

  const isConnected = keycloak.token && keycloak.authenticated;

  const isShowLoading = !userState.id && isConnected;

  return (
    <div>
      <div style={{ opacity: isShowLoading ? 0 : 1 }}>
        <Header />
      </div>
      {isShowLoading ? (
        <LoadingAllPage />
      ) : (
        <>
          <Header />
          <section className="HomeGetStarted d-flex align-items-stretch">
            <div className="container mx-auto d-flex">
              <div className="moveyoursoftware">
                <h1 className="title">
                  Strategic initiatives for software publishers
                </h1>
                <p></p>

                <div className="hubSoftwareVendor">
                  <div className="container mx-auto">
                    <ul className="listsoftwarevendor">
                      <li>
                        <img src={early} alt="" />
                        <h2 className="title">Early stage funding</h2>
                        <div className="textforitems">
                          Match with early-stage software investors based on
                          your company profile and preferences.
                        </div>
                        <Link to="/landing-find-inv" className="learnMore">
                          More info <img src={more} alt="" />
                        </Link>
                      </li>
                      <li>
                        <img src={later} alt="" />
                        <h2 className="title">Later stage funding</h2>
                        <div className="textforitems">
                          Match with investors to cover funding needs beyond A
                          round.
                        </div>
                        <Link to="/landing-find-inv" className="learnMore">
                          More info <img src={more} alt="" />
                        </Link>
                      </li>
                      <li>
                        <img src={ma} alt="" />
                        <h2 className="title">M&A</h2>
                        <div className="textforitems">
                          Buy and sell assets among Devinsider’s global
                          community of software publishers and institutional
                          investors.
                        </div>
                        <a className="learnMore linkDisable">Coming soon</a>
                      </li>
                      <li>
                        <img src={strategic} alt="" />
                        <h2 className="title">Strategic partnerships</h2>
                        <div className="textforitems">
                          Identify the most relevant technology partnerships
                          that help ensure the growth of your software business.
                        </div>
                        <Link to="/landing-find-prog" className="learnMore">
                          More info <img src={more} alt="" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="howItWorks">
            <div className="container mx-auto">
              <div className="textHow">
                <h2>
                  Access matchmaking <br />
                  features to connect <br />
                  with the best opportunities
                </h2>
              </div>
              <div className="imagehow">
                <img src={howitwork} alt="" />
              </div>
            </div>
          </section>
          <section className="solvebusiness">
            <div className="container mx-auto">
              <h1 className="title">Solve your business challenges</h1>
              <ul className="listLabelLink">
                <li>
                  <img src={ip} />
                  <p>Ip asset sale</p>
                </li>
                <li>
                  <img src={restructuring} />
                  <p>Restructuring</p>
                </li>
                <li>
                  <img src={bootstraping} />
                  <p>Bootstrapping</p>
                </li>
                <li>
                  <img src={white} />
                  <p>White labelling</p>
                </li>
                <li>
                  <img src={early2} />
                  <p>Early stage funding</p>
                </li>
                <li>
                  <img src={legal} />
                  <p>Legal IP protection</p>
                </li>
                <li>
                  <img src={asset} />
                  <p>IP asset monetization</p>
                </li>
                <li>
                  <img src={copyright} />
                  <p>Copyright VS . patent</p>
                </li>
                <li>
                  <img src={other} />
                  <p>Other asset monetization</p>
                </li>
                <li>
                  <img src={later2} />
                  <p>Later stage funding</p>
                </li>
                <li>
                  <img src={alternative} />
                  <p>Alternative financing</p>
                </li>
                <li>
                  <img src={maa} />
                  <p>Mergers and acquisitions</p>
                </li>
              </ul>
              <Link to="/community/home" className="joinConversationLink">
                Join the community
              </Link>
            </div>
          </section>
          <section className="mainSpons">
            {/* <div className="container mx-auto">
          <h1 className="title">Devinsider sponsors:</h1>
          <img className="imgSpons" src={sponsors} alt="" />
        </div> */}
          </section>
          <Footer />
          {understoodCoockies === false && (
            <div className="banner-cookies">
              <p>
                This website uses essential cookies to ensure its proper
                functioning. <br />
                This website doesn't use third party cookies.
              </p>
              <a
                href="javascript:;"
                className="btn btn-devinsider"
                onClick={(e) => {
                  e.preventDefault();
                  sessionStorage.setItem("cbasics", "true");
                  setUnderstoodCoockies(true);
                }}
              >
                {" "}
                I understand
              </a>
            </div>
          )}
        </>
      )}
    </div>
  );
}
