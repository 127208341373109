import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import { ISV, OEM_PROGRAM } from "../../../../service/constant";
import { Link } from "react-router-dom";
import { fileUrl } from "../../../../utils";
export type IPopsModal = {
  showModal: boolean;
  handleModal: () => void;
  manageCompany: () => void;
  validClauseAccount: () => void;
};
export default function ModalDecativeAccount(props: IPopsModal) {
  const userReducer = useSelector((state: rootState) => state.userReducer);

  const oemPage = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  let allProgram = userReducer?.oemProgramMembers;
  const userIsv = userReducer?.roles?.indexOf(ISV) !== -1;

  const userOEM = userReducer?.roles?.indexOf(OEM_PROGRAM) !== -1;

  const allOemMembers = oemPage.oemProgramMembers;

  const getRoleCurrentUser = () => {
    if (userOEM) {
      const userRole = allOemMembers.find(
        (Tuser) => Tuser.user.id == userReducer.id
      );
      return userRole.role;
    } else {
      return null;
    }
  };

  return (
    <Modal
      show={props.showModal}
      onHide={props.handleModal}
      className="modalDevinsider medium forDeactivate"
    >
      <Modal.Header closeButton>
        <Modal.Title>Close your account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="contentBorder row m-0 p-0 desactivepopup">
          <h3 className="title withIcon">
            <span className="icon-info"></span>
            Are you sure you want to continue ?
          </h3>
          <p>
            You are about to close your account. This is a permanent action
            <br /> and cannot be undone.
          </p>
          {userIsv && (
            <div className="programtodeactivate wrap">
              <p className="mb-1">
                <b>Important notice!</b>
              </p>
              <p>
                If you are the admin of a company page on Devinsider, please
                make <br />
                sure to add another admin to the page. If your company page does
                not have at least 1 active admin, the company page will be
                automatically deactivated and every member associated to your
                company page will be disconnected from the page.
                <br />
              </p>
              {getRoleCurrentUser() === "PROGRAM_OWNER" && (
                <Link
                  to={"#"}
                  onClick={(e) => {
                    e.preventDefault();
                    props.manageCompany();
                  }}
                >
                  Manage company page admins
                </Link>
              )}
            </div>
          )}
          {userOEM && getRoleCurrentUser() === "PROGRAM_OWNER" && (
            <div className="programtodeactivate wrap">
              <p className="mb-1">
                <b>Important notice!</b>
              </p>
              <p>
                Please make sure to assign a new program owner. If you close
                your account without assigning a new program owner, the program
                page and all the accounts of affiliated members will be
                automatically deleted. <br />
                If you are the program owner of multiple program pages you will
                need to assign a new program owner for each program to avoid the
                pages to be permanently deleted.
                <br />
                {true && (
                  <a onClick={() => props.manageCompany()}>
                    Assign new program owner.
                  </a>
                )}
              </p>

              <br />

              <p>
                If you don't want to permanently delete your account and program
                page(s) you have the option to unpublish your page. This make
                your page invisible for other Devinsider users and allows you to
                republish the page in the future.
                <br />
              </p>
            </div>
          )}
          {userOEM && getRoleCurrentUser() != "PROGRAM_OWNER" && (
            <div>
              <br />
              <p>You are currently affiliated to the following program(s).</p>
              {allProgram?.map((el: any, index: number) => (
                <div className="programtodeactivate no-program">
                  <div className="imgSv">
                    <img src={fileUrl(el?.oem_program?.photo_profil)} alt="" />
                  </div>
                  <div className="infoSv">
                    <h3 className="title p-0">
                      <p className="p-0">
                        Program {index + 1}: {el?.oem_program?.program_name}
                      </p>
                    </h3>
                    {el?.oem_program?.company_name}
                  </div>
                </div>
              ))}
              <div className="programtodeactivate wrap">
                <p className="mb-1">
                  <b>Important notice!</b>
                </p>
                <p>
                  By closing your account you will be permanently disconnected
                  from any program(s) you are affiliated to.
                </p>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancelbtn" onClick={props.handleModal}>
          Cancel
        </Button>
        <Button
          className="validate"
          type="button"
          onClick={() => props.validClauseAccount()}
        >
          Close your account
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
