/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { rootState } from "../../../redux/reducers";
import {
  getCompanyPageAction,
  getCompanyPageAttributeAction,
  updateCompanyPageAction,
} from "../../../redux/actions/company-page-isv-action/current_isv_action";
import {
  clearOtherIsvCahe,
  getOtherCompanyPageAction,
  getOtherCompanyPageAttributeAction,
} from "../../../redux/actions/company-page-isv-action/other_isv_action";
import { conversationType, getSStorage } from "../../../utils";
import { useParams, useHistory, useLocation } from "react-router";
import {
  HIDDEN_INFORMATION,
  INVESTOR,
  ISV,
  OEM_PROGRAM,
  url,
} from "../../../service/constant";
import {
  patchDataService,
  postJsonDataService,
} from "../../../service/applicatif/back-end-service";
var _ = require("lodash");

export default function UseProfileCompanyPageCtr() {
  const [isLoadingCompanyInfo, setIsLoadingCompanyInfo] = useState(true);

  const dispatch = useDispatch();

  const history = useHistory();

  const params: any = useParams();

  const location = useLocation();

  const idOtherUser = params?.id ? true : false;

  const state = useSelector((state: rootState) => state);

  //Dev #48042,V2.1 [Investor] Preview Mode of an ISV company profile
  const roleCurrentUser = state.userReducer.roles.filter(
    (role: string) => role !== "ROLE_USER"
  )[0];
  const idCurrentCompany = (() => {
    if (roleCurrentUser === ISV) return state.companyPageReducer.id;
    if (roleCurrentUser === INVESTOR)
      return state.companyInvestorPageReducer.id;
    if (roleCurrentUser === OEM_PROGRAM)
      return state.oemProgramReducer.program.id;
    return "";
  })();

  let companyPageObject = useSelector((state: rootState) =>
    params?.id ? state?.otherUserCompanyIsvReducer : state.companyPageReducer
  );

  let userObject = useSelector((state: rootState) => state.userReducer);
  let oemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  let investorPage = useSelector(
    (state: rootState) => state.companyInvestorPageReducer
  );
  const isInvestor = userObject.roles?.[0] === INVESTOR;

  /**************************************
   *
   * Use for show more and show less
   */
  const [index3, setindex3] = useState<number>(6);
  /**************************************
   *
   * Data completed , store we use to show in form select
   */
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [isShowErrorPartenerShip, setIsShowErrorPartenerShip] =
    useState<boolean>(false);

  //modal manage company page
  const [showManageCompanyPage, setShowManageCompanyPage] =
    useState<boolean>(false);

  /********************************************************
   *
   * Pays checked value to show in Technology Partnership interests
   */

  const [isShowPartenerShipModal, setisShowPartenerShipModal] =
    useState<boolean>(false);
  const [partenerShipSelected, setPartenerShipSelected] = useState<Array<any>>(
    []
  );
  const [currentSelected, setCurrentSelected] = useState<{
    value: number;
    label: string;
  }>();

  const [currentSelectedList, setCurrentSelectedList] = useState<
    Array<{ value: number; label: string }>
  >([]);

  const [technologiesPartnership, setTechnologiesPartnership] = useState<
    Array<{ value: number; label: string }>
  >(
    dataCompleted?.partners?.map((el: { id: number; name: string }) => ({
      value: el.id,
      label: el.name,
    })) || []
  );
  const [checkedSoftware, setCheckedSoftware] = useState<string>("yes");

  const [configOtherCompany, setConfigOtherCompany] = useState<{
    is_favorite: boolean;
    matching: number | string;
    user: {
      id: number;
      first_name: string;
      last_name: string;
    };
  }>({
    is_favorite: false,
    matching: "",
    user: {
      first_name: "",
      id: 0,
      last_name: "",
    },
  });
  /****************************************************
   *
   * Add partener when click add in list
   */
  const addListOption = (data: any) => {
    if (currentSelected) {
      let currentOptionList = technologiesPartnership?.filter(
        (el: { value: number; label: string }) => el.value !== data.value
      );
      setCurrentSelectedList([...currentSelectedList, currentSelected]);
      setTechnologiesPartnership(currentOptionList);
      setCurrentSelected({ label: "", value: 0 });
    }
  };

  /****************************************************
   *
   * remove partener when click remove in list
   */
  const removeSelected = (id: number) => {
    let data = currentSelectedList?.find(
      (el: { value: number; label: string }) => el.value === id
    );
    let tempValue = currentSelectedList?.filter(
      (el: { value: number; label: string }) => el.value !== id
    );
    setCurrentSelectedList(tempValue);
    if (data) {
      setTechnologiesPartnership([data, ...technologiesPartnership]);
    }
  };
  /****************************************************
   *
   * Add partener ship data
   */
  const addParterShip = (params: any) => {
    let found = partenerShipSelected?.find(
      (el: any) => el.idSegment == params.idSegment && el.name == params.name
    );

    if (!found) {
      setPartenerShipSelected([...partenerShipSelected, params]);
    } else {
      setPartenerShipSelected(
        partenerShipSelected?.filter(
          (elem: any) => elem.idSegment != params.idSegment
        )
      );
    }
  };

  /************************************************
   *
   * Get attribute  attribute
   */
  const cbAttributParteners = (response: any) => {
    if (response && response?.status === 200) {
      let tempParteners = response?.data?.map((el: any) => ({
        value: el.id,
        label: el.name,
      }));

      let defaultValuePartener = dataCompleted?.partners?.map(
        (el: { id: number; name: string }) => ({
          value: el.id,
          label: el.name,
        })
      );
      let valueAfterFilter = _.xorWith(
        tempParteners,
        defaultValuePartener,
        (p: any, other: any) => other.value == p.value
      );
      setTechnologiesPartnership(valueAfterFilter);
      setCurrentSelectedList(tempParteners);
    }
  };

  /*****************************************************
   *
   * Get parteners data selected
   */
  const setPartenerAttribut = () => {
    let attributes = "partners";
    const oemIfExist =
      state.userReducer?.roles?.indexOf(OEM_PROGRAM) !== -1
        ? state.oemProgramReducer.program.id
        : "";
    let idCompany =
      oemIfExist !== "" ? params?.id + "/" + oemIfExist : params?.id || "";
    if (idOtherUser) {
      dispatch(
        getOtherCompanyPageAttributeAction(
          attributes,
          idCompany,
          cbAttributParteners
        )
      );
    } else {
      dispatch(getCompanyPageAttributeAction(attributes, cbAttributParteners));
    }
  };

  // Get completed attribute Target industrie, to show with data
  const cbEomProgram = (response: any) => {
    if (response && response?.status === 200) {
      if (isShowPartenerShipModal) {
        setLoader(false);
        setisShowPartenerShipModal(false);
      }
    } else if (response?.status === 403) {
      history.push("/profil-not-found");
    }
  };

  const setEomProgramInterest = () => {
    let attributes = "oemProgramSegmentInterest";
    const oemIfExist =
      state.userReducer?.roles?.indexOf(OEM_PROGRAM) !== -1
        ? state.oemProgramReducer.program.id
        : "";
    let idCompany =
      oemIfExist !== "" ? params?.id + "/" + oemIfExist : params?.id || "";
    if (idOtherUser) {
      dispatch(
        getOtherCompanyPageAttributeAction(attributes, idCompany, cbEomProgram)
      );
    } else {
      dispatch(getCompanyPageAttributeAction(attributes, cbEomProgram));
    }
  };

  const cbOemSegmentInterestText = (response: any) => {
    if (response && response?.status === 200) {
      const txtPartenare = response?.data || "[]";
      setPartenerShipSelected(JSON.parse(txtPartenare));
    }
  };

  const setEomProgramText = () => {
    let attribute = "oemProgramSegmentInterestText";
    const oemIfExist =
      state.userReducer?.roles?.indexOf(OEM_PROGRAM) !== -1
        ? state.oemProgramReducer.program.id
        : "";
    let idCompany =
      oemIfExist !== "" ? params?.id + "/" + oemIfExist : params?.id || "";

    if (idOtherUser) {
      dispatch(
        getOtherCompanyPageAttributeAction(
          attribute,
          idCompany,
          cbOemSegmentInterestText
        )
      );
    } else {
      dispatch(
        getCompanyPageAttributeAction(attribute, cbOemSegmentInterestText)
      );
    }
  };

  const getAttribute = () => {
    setEomProgramText();
    setEomProgramInterest();
    setPartenerAttribut();
  };

  const cbUpdateEomProgram = async (response: any) => {
    if (response && response?.status === 200) {
      getAttribute();
    }
  };

  const updateTechnologiePartener = () => {
    if (partenerShipSelected?.length <= 0) {
      setIsShowErrorPartenerShip(true);
    } else {
      let params = {
        oemProgramSegmentInterest: JSON.stringify(
          partenerShipSelected.map((el: any) => el.idSegment)
        ),
        oemProgramSegmentInterestText: JSON.stringify(
          partenerShipSelected || []
        ),
        partners: JSON.stringify(
          currentSelectedList.map((el: any) => el.value)
        ),
      };
      setLoader(true);
      dispatch(
        updateCompanyPageAction(
          params,
          companyPageObject.id,
          cbUpdateEomProgram
        )
      );
    }
  };
  //////////////////////////////////////////////////////////////////////////////

  const cancelTechnologieParteners = () => {
    setisShowPartenerShipModal(false);
    setPartenerShipSelected(
      JSON.parse(companyPageObject?.oemProgramSegmentInterestText)?.map(
        (el: any) => ({
          idSegment: el.idSegment,
          name: el.name,
        })
      )
    );
  };

  useEffect(() => {
    setIsShowErrorPartenerShip(false);
    return () => {};
  }, [partenerShipSelected]);

  const cb = (response: any) => {
    const isLocked = response?.data?.company_name?.includes(HIDDEN_INFORMATION);
    const isUrlUnloced = location?.pathname?.includes("company-unlocked");
    const isLockedCompanyPage = isLocked && isUrlUnloced;
    if (isLockedCompanyPage) {
      history.push("/profil-not-found");
      return;
    } else if (response?.data?.id) {
      getAttribute();
    }
    setIsLoadingCompanyInfo(false);
  };

  /**********************************************************
   *
   * Get company page information if company page exist
   */
  useEffect(() => {
    if (!idOtherUser) {
      const idCompanyPage =
        companyPageObject?.id || userObject.isvInCompanyPage?.company_page?.id;
      dispatch(getCompanyPageAction(idCompanyPage?.toString() || "0", cb));
    }

    if (idOtherUser) {
      const isOemPreviewIsv =
        state.userReducer?.roles?.indexOf(OEM_PROGRAM) !== -1 &&
        state.oemProgramReducer.program.id?.toString() !== "0";
      if (isOemPreviewIsv) {
        dispatch(
          //Dev #48042, V2.1 [Investor] Preview Mode of an ISV company profile
          getOtherCompanyPageAction(
            params?.id + "?r=" + roleCurrentUser + "&i=" + idCurrentCompany,
            cb,
            state.oemProgramReducer.program.id
          )
        );
      } else {
        //Dev #48042, V2.1 [Investor] Preview Mode of an ISV company profile
        dispatch(
          getOtherCompanyPageAction(
            params?.id + "?r=" + roleCurrentUser + "&i=" + idCurrentCompany,
            cb
          )
        );
      }
    }

    return () => {};
  }, []);

  const backResultPanel = () => {
    history.goBack();
  };

  const contactIsv = () => {
    if (params?.id) {
      history.push("/contact/discussion", {
        userId: params.id,
        discussionType:
          userObject.roles[0] === OEM_PROGRAM
            ? conversationType.oem_to_isv
            : conversationType.investor_to_isv,
      });
    }
  };

  useEffect(() => {
    async function getInfoConfigCompany() {
      const role = userObject?.roles?.filter((el) => el !== "ROLE_USER");
      const dataToSend = {
        operation:
          role?.[0] === OEM_PROGRAM ? "OEM_FIND_ISV" : "INVESTOR_FIND_ISV",
        source: role?.[0] === OEM_PROGRAM ? oemProgram.id : investorPage.id,
        target: params.id,
      };
      const response = await postJsonDataService(
        url.company.get_other_company_config,
        dataToSend,
        userObject.token
      );
      if (response?.status === 200) {
        setConfigOtherCompany(response?.data?.details);
      }
    }
    if (params.id) {
      getInfoConfigCompany();
    }
  }, [params.id]);

  const addFavorite = async () => {
    if (params?.id) {
      const role = userObject?.roles?.filter((el) => el !== "ROLE_USER");
      const paramsId = params?.id;
      let urlFavorite =
        role?.[0] === OEM_PROGRAM
          ? url?.findIsv?.mark_as_favorite +
            "/" +
            oemProgram.id +
            "/" +
            paramsId
          : url?.matching_investor_isv?.add_favorie + paramsId;

      let result: any = await patchDataService(
        urlFavorite,
        {},
        userObject?.token
      );
      if (result?.status === 200) {
        setConfigOtherCompany({
          ...configOtherCompany,
          is_favorite: !configOtherCompany?.is_favorite,
        });
      }
    }
  };

  useEffect(() => {
    return () => {
      if (idOtherUser) {
        dispatch(clearOtherIsvCahe());
      }
    };
  }, []);
  return {
    index3,
    companyPageObject,
    setindex3,
    loader,
    userObject,
    dataCompleted,
    technologiesPartnership,
    addListOption,
    addParterShip,
    removeSelected,
    setCurrentSelected,
    checkedSoftware,
    setCheckedSoftware,
    partenerShipSelected,
    setPartenerShipSelected,
    currentSelected,
    currentSelectedList,
    updateTechnologiePartener,
    isShowPartenerShipModal,
    setisShowPartenerShipModal,
    cancelTechnologieParteners,
    isShowErrorPartenerShip,
    showManageCompanyPage,
    setShowManageCompanyPage,
    idOtherUser,
    backResultPanel,
    contactIsv,
    configOtherCompany,
    addFavorite,
    isInvestor,
    isLoadingCompanyInfo,
  };
}
