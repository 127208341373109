/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { rootState } from '../../../../redux/reducers';
import {
  getCompanyPageAttributeAction,
  updateCompanyPageAction,
} from '../../../../redux/actions/company-page-isv-action/current_isv_action';
import * as T from '../../types/types-company-page';
import { useParams, useHistory } from 'react-router-dom';
import { getOtherCompanyPageAttributeAction } from '../../../../redux/actions/company-page-isv-action/other_isv_action';
import { OEM_PROGRAM } from '../../../../service/constant';

export default function UseTargetIndustrieCtr() {
  const dispatch = useDispatch();
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(sessionStorage.getItem('completed_account') || '{}')
  );

  const params: { id: string } = useParams();

  const history = useHistory();

  const idOtherUser = params?.id ? true : false;

  const state = useSelector((state: rootState) => state);
  const userReducer = state.userReducer;
  const oemProgramObject = state.oemProgramReducer.program;

  let companyPageObject = useSelector((state: rootState) =>
    idOtherUser ? state?.otherUserCompanyIsvReducer : state.companyPageReducer
  );
  const [index2, setindex2] = useState<number>(6);
  const [loaderTargetIndustrie, setLoader] = useState<boolean>(false);
  /*********************************************
   *
   * Company software Target industry
   */
  const [showRequestTargetIndustry, setShowRequestTargetIndustry] =
    useState(false);

  const [companyTarget, setcompanyTarget] = useState<Array<T.IListTarget>>([]);

  const [companyTargetSecound, setcompanyTargetSecond] = useState<
    Array<T.IListTarget>
  >([]);
  const [lastCompanyTarget, setLastcompanyTargetSecond] = useState<
    Array<T.IListTarget>
  >([]);
  const [companyTargetSelected, setCompanyTargetSelected] = useState<
    Array<T.IListTarget>
  >(companyPageObject.targeted_industry || []);

  const [isHaveErrorTargetCompany, setIsHaveErrorTargetCompany] =
    useState<boolean>(false);

  const addTargetCompany = (dataMake: T.IListTarget) => {
    let found =
      companyTargetSelected &&
      companyTargetSelected?.find((el: T.IListTarget) => el.id == dataMake.id);
    if (!found) {
      setCompanyTargetSelected([...companyTargetSelected, dataMake]);
    } else {
      setCompanyTargetSelected(
        companyTargetSelected &&
          companyTargetSelected.filter(
            (elem: T.IListTarget) => elem.id != dataMake.id
          )
      );
    }
  };

  useEffect(() => {
    setIsHaveErrorTargetCompany(false);
    return () => {};
  }, [companyTargetSelected]);

  useEffect(() => {
    async function getTargetCompany() {
      let dataTargetCompany = dataCompleted && dataCompleted?.targetedIndustry;
      let divider = Math.floor(dataTargetCompany?.length / 3);
      let firstColumn = dataTargetCompany?.slice(0, divider);
      let secondColumn = dataTargetCompany?.slice(divider, divider * 2);
      let lastComumn = dataTargetCompany?.slice(divider * 2);
      setcompanyTarget(firstColumn);
      setcompanyTargetSecond(secondColumn);
      setLastcompanyTargetSecond(lastComumn);
    }
    getTargetCompany();
    return () => {};
  }, []);

  useEffect(() => {
    setIsHaveErrorTargetCompany(false);
    return () => {};
  }, [companyTargetSelected]);

  /**
   *
   * @param data
   *
   * Update info TARGET  industrie  company Page
   */

  const updateTargetIndustrie = () => {
    if (companyTargetSelected?.length <= 0) {
      setIsHaveErrorTargetCompany(true);
    } else {
      let dataInfo = {
        targetedIndustry: JSON.stringify(
          companyTargetSelected &&
            companyTargetSelected.map((el: T.IListTarget) => el.id)
        ),
      };
      setLoader(true);
      dispatch(
        updateCompanyPageAction(
          dataInfo,
          companyPageObject.id,
          cbUpdateTargetIndustrie
        )
      );
    }
  };

  const cbUpdateTargetIndustrie = (data: any) => {
    if (data && data.status === 200) {
      setCompanyTargetAttribure();
    }
  };

  const setCompanyTargetAttribure = () => {
    let attribute = 'targetedIndustry';
    let currentOemIfExists =
      userReducer?.roles?.indexOf(OEM_PROGRAM) !== -1 ? oemProgramObject.id : '';
    let idCompany =
      currentOemIfExists !== ''
        ? params?.id + '/' + currentOemIfExists
        : params?.id || '';
    if (idOtherUser) {
      dispatch(
        getOtherCompanyPageAttributeAction(
          attribute,
          idCompany,
          cbTargetIndustrie
        )
      );
    } else {
      dispatch(getCompanyPageAttributeAction(attribute, cbTargetIndustrie));
    }
  };

  useEffect(() => {
    const isEmptyTargetedIndustry =
      companyPageObject?.id?.toString() !== '0' &&
      companyPageObject?.targeted_industry?.length < 1 &&
      !idOtherUser;

    if (isEmptyTargetedIndustry) setCompanyTargetAttribure();

    if (idOtherUser) setCompanyTargetAttribure();

    return () => {};
  }, []);

  // Get completed attribute Target industrie, to show with data
  const cbTargetIndustrie = (response: any) => {
    if (response && response?.status === 200) {
      setCompanyTargetSelected(response.data);
      if (showRequestTargetIndustry) {
        setLoader(false);
        setShowRequestTargetIndustry(false);
      }
    } else if (response?.status === 403) {
      history.push('/profil-not-found');
    }
  };

  const cancelUpdataTargeted = () => {
    setShowRequestTargetIndustry(false);
    setCompanyTargetSelected(companyPageObject.targeted_industry || []);
  };
  return {
    show: showRequestTargetIndustry,
    cancelUpdataTargeted,
    companyTarget: companyTarget,
    companyTargetSecound: companyTargetSecound,
    lastCompanyTarget: lastCompanyTarget,
    errorData: isHaveErrorTargetCompany,
    companyTargetSelected: companyTargetSelected,
    addTargetCompany: addTargetCompany,
    updateTargetIndustrie,
    loaderTargetIndustrie,
    setShowRequestTargetIndustry,
    showRequestTargetIndustry,
    index2,
    setindex2,
  };
}
