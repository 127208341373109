/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { rootState } from "../../../redux/reducers";
import { getLooUpKeyPostMA, getMonth, isExist, isModerator } from "../../../utils";
import { PaymentPopup } from "../../container/payment-popup/payment-popup";
import {
  GUEST,
  INVESTOR,
  ISV,
  OEM_PROGRAM,
  RESELLER,
  url,
} from "../../../service/constant";
import { LoginModal } from "../login/login";
import { getDataWithoutToken } from "../../../service/api";
import useEventListener from "../../../hooks/event-handler";
const urlType = {
  favorites: "/favorite",
  listing: "/listing",
};

type IProps = {
  searchText?: string | undefined;
  handleChange?: (txt: string) => void;
  handleClick?: () => void;
};
export default function SecondHeaderMA(props: IProps) {
  const history = useHistory();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [allText, setAllText] = useState(["", "", ""]);
  const [showAdsModalP, setShowAdsModalP] = useState<boolean>(false);
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const urlPath = window.location.href;
  const [chargingPlanDetails, setChargingPlanDetails] =
    useState<boolean>(false);
  const now = new Date();
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const [freeNumber, setFreeNumber] = useState<number>(0);
  const oemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const [currentMonth, setCurrentMonth] = useState<string>(
    getMonth(now.getMonth()) + " " + now.getFullYear()
  );
  const companyInvestorPage = useSelector(
    (state: rootState) => state?.companyInvestorPageReducer
  );
  const goToHomeMergers = () => {
    history.push("/ma/home");
  };

  const goToFavoritesMergers = () => {
    if (isConnected) {
      history.push("/ma/favorites");
      return;
    }
    setShowLogin(true);
  };
  const goToMylistingsMergers = () => {
    isConnected ? history.push("/ma/listings") : setShowLogin(true);
  };

  const isIsv = userReducer?.roles?.indexOf(ISV) !== -1;
  const isGuest = userReducer?.roles?.indexOf(GUEST) !== -1;
  const isReseller = userReducer?.roles?.indexOf(RESELLER) !== -1;
  const isInvestor = userReducer?.roles?.indexOf(INVESTOR) !== -1;
  const isOem = userReducer?.roles?.indexOf(OEM_PROGRAM) !== -1;

  const subUrl = window.location.href;

  const renderActive = (type: string) => {
    return subUrl?.includes(type) ? "active" : "";
  };

  const isConnected = isExist(userReducer.token) && userReducer.enable;

  const checkPayementMa = () => {
    const isCompteActive = userReducer?.enable;

    if (isCompteActive) {
      //Is isv page not completed
      const showCompleteInfoAlert =
        userReducer?.roles?.[0] === ISV &&
        !userReducer?.isvInCompanyPage?.company_page?.completed;

      //Is investor page not valide
      const investorNotValidePage =
        userReducer?.roles?.[0] === INVESTOR &&
        !companyInvestorPage.validate_by_devinsider;

      if (showCompleteInfoAlert) {
        setAllText([
          "Oops...",
          "",
          "You must be affiliated to a company page in order to publish Mergers and acquisitions",
        ]);
        setShowAdsModalP(true);
        return;
      }

      if (investorNotValidePage) {
        setAllText([
          "Oops...",
          "",
          "Please publish your investor page to access publish Mergers and acquisitions",
        ]);
        setShowAdsModalP(true);
        return;
      }
      setShowPaymentModal(true);
    } else {
      setShowLogin(true);
    }
  };

  const paymentMethodCallBack = () => {};

  const handleStatusOk = () => {};

  useEffect(() => {
    async function getPlanDetails() {
      setChargingPlanDetails(true);
      const response = await getDataWithoutToken(
        url.settings.billingCenter + "/information/plan_details",
        { month: currentMonth, oem: oemProgram.id },
        userReducer.token
      );

      if (response?.status === 200) {
        const dynamicKey = isIsv
          ? "isv_free_corporate_ads"
          : isGuest
          ? "guest_free_corporate_ads"
          : isReseller
          ? "reseller_corporate_ads"
          : isInvestor
          ? "investor_free_corporate_ads"
          : isOem
          ? "oem_program_corporate_ads"
          : "isv_free_corporate_ads";
        const freeNumberStatus: any = response?.data?.free_status[dynamicKey];
        setFreeNumber(freeNumberStatus?.number_left || 0);
      }
      setChargingPlanDetails(false);
    }
    if (userReducer.token) {
      getPlanDetails();
    }
  }, [currentMonth, userReducer.token]);

  const keyEnter = ({ key }: any) => {
    if (key === "Enter") {
      props?.handleClick && props?.handleClick();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return (
    <>
      <PaymentPopup
        show={showPaymentModal}
        title={"Post corporate development ad"}
        setShowPaymentModal={setShowPaymentModal}
        product={{
          lookupKey: getLooUpKeyPostMA(userReducer?.roles?.[0]),
          name: "Post corporate development ad",
        }}
        successCallback={paymentMethodCallBack}
        free={freeNumber}
        isPriceInfo={true}
        handleStatusOk={handleStatusOk}
        priceInfoCallback={() => {
          history.push("/ma/post");
        }}
      />

      <div className="secondMenu">
        <div className="container mx-auto">
          <div className="leftItemHeader">
            <h2 className="title">
              <a
                className="colorInherit"
                onClick={(e) => {
                  e.preventDefault();
                  goToHomeMergers();
                }}
              >
                Mergers and acquisitions
              </a>
            </h2>
            <div className="menuDynamic bl">
              <div className="item">
                <a
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    goToFavoritesMergers();
                  }}
                  className={renderActive(urlType?.favorites)}
                >
                  Favorites
                </a>
              </div>
              <div className="item">
                <a
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    goToMylistingsMergers();
                  }}
                  className={renderActive(urlType?.listing)}
                >
                  My listings
                </a>
              </div>
              <div className="item">
                <div className="searchWord classified">
                  <input
                    type="text"
                    className="keyword"
                    value={props?.searchText}
                    onChange={(evt) =>
                      props?.handleChange &&
                      props?.handleChange(evt?.target?.value)
                    }
                  />
                  <button onClick={props?.handleClick}>
                    <span className="icon-search"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="rightItemHeader">
            <div className="item">
              <a
                href="javascript:;"
                className="startPost"
                onClick={(e) => {
                  e.preventDefault();
                  if(isModerator(userReducer?.roles)){
                    history.push("/ma/post");
                    return
                  }
                  if (!chargingPlanDetails) {
                    checkPayementMa();
                    return
                  }
                }}
              >
                Post an ad
              </a>
            </div>
          </div>
        </div>
        <Modal show={showAdsModalP} className="small forDesactivate">
          <Modal.Header>
            <div className="headerpermission noStep">
              <h2 className="title">{allText?.[0]}</h2>
              <NavLink
                to="#"
                className="close"
                onClick={(e) => {
                  e.preventDefault();
                  setShowAdsModalP(false);
                }}
              >
                <span className="icon-close-pop"></span>
              </NavLink>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="contentBorder row m-0 p-0 desactivepopup">
              <h3 className="title withIcon">
                <span className="icon-info"></span>
                {allText?.[1]}
              </h3>

              <p>{allText?.[2]}</p>
              <p>{allText?.[3]}</p>
            </div>
          </Modal.Body>
        </Modal>
        <LoginModal
          urlType={urlPath.indexOf("community") !== -1 ? "community" : ""}
          show={showLogin}
          setShow={setShowLogin}
        />
      </div>
    </>
  );
}
