import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { rootState } from '../../../redux/reducers';
import {
  getDataMicroService,
  postDataMicroService,
} from '../../../service/applicatif/back-end-service';
import { OEM_PROGRAM, url } from '../../../service/constant';

const currencies: { [key: string]: string } = {
  usd: '$',
  eur: '€',
};

export type PaymentMethod = {
  id: string;
  currency: string;
  card: {
    brand: string;
    last4: string;
  };
};

export default function usePaymentHook(
  show: boolean,
  product?: {
    lookupKey: string;
    name: string;
  },
  isPriceInfo?: boolean,
  free?: number,
  monthlyBudgetReached?: boolean
) {
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const userEmail = userReducer.email;
  const isOem = userReducer?.roles?.indexOf(OEM_PROGRAM) !== -1;
  const oemProgramId = useSelector(
    (state: rootState) => state?.oemProgramReducer.program.id
  );

  const [step, setStep] = useState<'product-info' | 'payment-info'>('product-info');
  const [modalSize, setModalSize] = useState<'small' | 'large'>('small');
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [prices, setPrices] = useState<{
    [key: string]: number;
  }>();
  const [currency, setCurrency] = useState<string | undefined>();
  const [productPrice, setProductPrice] = useState({
    amount: 0,
    tax: 0,
    currency: '',
    totalAmount: 0,
  });
  const [buttonLoader, setButtonLoader] = useState(false);
  const [unlockAllowed, setUnlockAllowed] = useState<boolean>(true);


  useEffect(() => {
    if (product && show && !free && !prices)
      getDataMicroService(
        url.microservice.payment.get_product_prices + product.lookupKey,
        isOem ? { oem_program_id: oemProgramId } : {},
        userReducer.token
      )
        .then((response) => {
          if (response.data.message === 'success') {
            const productPrices = response.data.data.prices;
            const curr = productPrices.usd === undefined ? 'eur' : 'usd';
            setCurrency(curr);
            setPrices(productPrices);
            setProductPrice({
              ...productPrice,
              currency: curr,
              amount: response.data.data.prices[curr]
                ? response.data.data.prices[curr]
                : 0,
              tax: 0,
              totalAmount: response.data.data.prices[curr]
                ? response.data.data.prices[curr]
                : 0,
            });
            setPaymentMethod(response.data.data.payment_method);
            setUnlockAllowed(response.data.data.hasOwnProperty('allow_unlock') ? response.data.data.allow_unlock : false);
          } else {
            toast('Error getting product price');
            console.log(response.data.details);
          }
        })
        .catch((error) => {
          toast('Error getting product price');
          console.log(error.message);
        });
    else if (!show) {
      setStep('product-info');
      // setPrices(undefined);
      // setPaymentMethod(undefined);
      setButtonLoader(false);
      setModalSize('small');
    }
  }, [isOem, oemProgramId, prices, productPrice, product, free, userReducer.token, show, isPriceInfo, monthlyBudgetReached]);

  const getProductPriceWithTax = (
    country: string,
    vat: {
      has_vat: boolean;
      vat_number: string;
      company_name: string;
    }
  ) => {
    return postDataMicroService(
      url.microservice.payment.get_product_price_with_tax +
        product?.lookupKey +
        '/' +
        country,
      { vat: vat },
      userReducer.token
    );
  };

  const getCurrencySymbol = (cur?: string) => {
    return cur ? currencies[cur] : currencies[currency || 'usd'];
  };

  return {
    prices,
    setPrices,
    paymentMethod,
    setPaymentMethod,
    currency,
    setCurrency,
    getProductPriceWithTax,
    getCurrencySymbol,
    productPrice,
    setProductPrice,
    userEmail,
    step,
    setStep,
    isOem,
    modalSize,
    setModalSize,
    monthlyBudgetReached,
    unlockAllowed,
    buttonLoader,
    setButtonLoader
  };
}
